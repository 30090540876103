import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SelectCustom from "../../../UI/SelectCustom/SelectCustom"
import CheckBoxCustom from "../../../UI/CheckBoxCustom/CheckBoxCustom"
import ButtonCustom from "../../../UI/ButtonCustom/ButtonCustom"
import FormControl from '@mui/material/FormControl';
import useIsMobile from "../../../Utils/useIsMobile";
import RequestApi from "../../../Request-api/RequestApi"
import { useDispatch } from "react-redux";
import { setAuthLogin } from '../../../Redux/Reducers/authLoginReducer';
import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import { RECAP_KEY } from '../../../constants';
import ReCAPTCHA from "react-google-recaptcha";
import { Backdrop, Box, CircularProgress, Grid } from '@mui/material';

const styles = {
  width: '100%',
  fontFamily: "'Lato', sans-serif",
  height: '46px'
}

const Login = ({ chOption, docs }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [recordar, setRecordar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    docType: 2,
    docNum: "",
    pass: "",
    showPassword: false,
    tokenRecaptcha: ""
  })

  const handleAceptar = () => {
    try{
      setLoading(true)
      window.grecaptcha.reset()
      return window.grecaptcha.execute()
    } catch(e) {
      return window.location.reload();
    }
  }


  const handleRecaptcha = (token) => {
    if (token) {
      handleLogin(token);
    }
  }

  const handleRecordar = (e) => {
    setRecordar(e.target.checked)
  }

  useEffect(() => {
    if (sessionStorage.getItem("authLogin") && sessionStorage.getItem("token")) {
      history.push('/pacientes')
    }
    const doc = localStorage.getItem('savedDoc');
    if (doc) {
      setState({
        ...state,
        docNum: doc
      })
    }

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleAceptar();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])

  const getTipoDoc = (value) => {
    return docs.find(obj => obj.id == value)
  }


  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  };

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const { isMobile } = useIsMobile();
  const widthText = "94%"

  const handleLogin = (token) => {
    RequestApi.login({
      'nroDoc': state.docNum,
      'tipoDoc': getTipoDoc(state.docType),
      'password': state.pass,
      'recaptchaResponse': token
    }).then(data => {
      if (data.status === 0 && data.usuario) {
        if (recordar) {
          localStorage.setItem('savedDoc', state.docNum)
        }
        dispatch(setAuthLogin(data))
        history.push('/pacientes');
      } else if (data.status === 1) {
        Swal.fire({
          icon: 'info',
          title: 'Por favor, continúe por aquí',
          text: 'Te invitamos a que ingreses por nuestra web de socios',
          confirmButtonText: 'Ir',
          willClose: () => { window.location.reload() }
        }).then((result) => {
            if (result.isConfirmed) {
                window.open('https://www.swissmedical.com.ar/prepagaclientes/login')
            }
        })
      } else if (data.status === 2) {
        //aceptar producto NS
        history.push(`/registerlite/${data.usuario.idPersona}?access=${data.token}`);
      } 
      else if (data.status === -1) {
        Swal.fire({
          icon: 'error',
          title: 'Credenciales incorrectas',
          text: 'Por favor, verifique que su contraseña esté correcta e intentelo nuevamente.'
        })
      }
    })
    .catch(e => {
      Swal.fire({
        icon: 'error',
        title: 'Ups.. tuvimos un problema',
        text: 'Estamos teniendo problemas con nuestros servicios. Por favor, intentelo más tarde.'
      })
    }).finally(()=>{
      setLoading(false)
    })
  }

  return (
    <>
      <FormControl sx={{justifyContent: 'center', width: "100%", alignItems: "center", px: {lg:"3rem"}, maxWidth: "400px"}}>
        <img style={{width: '156px', marginBottom: "5rem" }} alt="logo swiss centros médicos" src='/images/cma_horizontal.svg'/>
        <Box sx={{width: "100%"}}>
          <SelectCustom
            sx={{width: "100%", maxWidth: "none", m: 0}}
            name='docType'
            label="Tipo de documento"
            minWidth={isMobile && widthText}
            options={docs?.map(d => { return { name: d.name, value: d.id } })}
            onChange={handleOnChange}
            defaultValue={2}
          ></SelectCustom>
          <p className="textInputs" style={{ marginBottom: '8px' }}  >Número de documento</p>
          <OutlinedInput sx={styles} placeholder="Ingresá el número de documento" name='docNum' value={state.docNum} onChange={handleOnChange} ></OutlinedInput>
        </Box>
        <Box sx={{width: "100%"}}>
          <p className="textInputs" style={{ marginBottom: '8px' }} >Contraseña</p>
          <OutlinedInput sx={styles} type={state.showPassword ? 'text' : 'password'} placeholder="Ingresá una contraseña" name='pass' value={state.pass} onChange={handleOnChange} endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }></OutlinedInput>

        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" pt="1rem">
              <p className="checkBox"><CheckBoxCustom onChange={handleRecordar} sx={{p:0, pr: ".5rem"}} />Recordar</p>
              <p onClick={chOption} className="textResetPass">¿Olvidaste la contraseña?</p>
        </Box>
        <ButtonCustom onClick={handleAceptar} width={"100%"} name="Ingresar" />
        <div style={{ display: "flex", width: "100%" }}>
          <p className="textAcaunt" >¿Todavía no tenes cuenta?</p>
          <a href="/register" className="textCreateAcaunt">Crear Cuenta</a>
        </div>
      </FormControl>
      <ReCAPTCHA
        sitekey={RECAP_KEY}
        size="invisible"
        onChange={handleRecaptcha}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default Login;
