import { Button, Drawer, Typography, Container } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import useIsMobile from "../../Utils/useIsMobile";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../Redux/Reducers/signinModalReducer";
import { useEffect } from "react";

const styles = {
    
    "& .MuiDrawer-paper": {
        backgroundColor: "unset",
        boxShadow: "none"
    },
    "& .contenedor": {
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#fff",
        marginTop: "4.5rem",
        boxShadow: "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)"
    },
    "& .headerTitle": {
        fontFamily: "'Lato', sans-serif",
        fontWeight: "bold",
        fontSize: "2rem",
        marginTop: "-2rem"
    },
    "& .headerIcon": {
        color: "#E4002B",
        fontSize: "3rem",
        marginTop: "-10px",
        marginBottom: "2rem"
    },
    "& .subtitle": {
        fontFamily: "'Lato', sans-serif",
        my: ".8rem"
    },
    "& .btn": {
        fontFamily: "'Lato', sans-serif",
        color: "#000",
        verticalAlign: "middle",
        border: "1px solid",
        borderRadius: "4px",
        textTransform: "initial",
        width: "22rem",
        my: ".5rem",
        opacity: ".7"

    },
    "& .closeIcon": {
        position: "absolute",
        right: "1rem",
        top: "1rem"
    }
}

const MenuIngresar = () => {
    const { isMobile } = useIsMobile();
    const dispatch = useDispatch();
    const { isOpen } = useSelector(state => state.signinModal)

    const handleNoSocioBtn = () => {
        window.open('/login', '_blank');
    }

    const handleSocioBtn = () => {
        window.open('https://www.swissmedical.com.ar/prepagaclientes/login', '_blank');
    }

    return (
        <>
            <Drawer
                sx={{ ...styles,  maxWidth: isMobile ? "100%" : "40%", "& .MuiPaper-root": { height: isMobile ? "80%" : "100%"} }}
                anchor={isMobile ? 'bottom' : 'right'}
                open={isOpen}
                onClose={() => dispatch(toggleOpen(false))}
            >
                <Container className="contenedor">
                    <CloseIcon className="closeIcon" onClick={() => dispatch(toggleOpen(false))} />
                    <Typography className="headerTitle">Ingresar</Typography>
                    <HorizontalRuleIcon className="headerIcon"/>
                    <Typography className="subtitle">¿Tenés Swiss Medical Medicina Privada?</Typography>
                    <Button className="btn" onClick={handleSocioBtn}>Estoy asociada/o a Swiss Medical Medicina Privada</Button>
                    <Button className="btn" onClick={handleNoSocioBtn}>Tengo otra cobertura médica o soy particular</Button>
                </Container>
            </Drawer>
        </>
    )
}

export default MenuIngresar;