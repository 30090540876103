import { useState } from 'react';
import ModalAlertValidarCuenta from '../../pages/Clientes/HomeClientes/Items/Validacion/Steps/ModalAlertValidarCuenta/ModalAlertValidarCuenta';
import { items } from '../../Data/NavbarHome.json'
import "./MenuClientes.css";
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import PersonIcon from '@mui/icons-material/Person';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import { Drawer, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, createSvgIcon } from '@mui/material';
import useIsMobile from "../../Utils/useIsMobile"
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {resetAuthLogin} from '../../Redux/Reducers/authLoginReducer';

const FamiliaresIcon = createSvgIcon(
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 42C21 42 18 42 18 39C18 36 21 27 33 27C45 27 48 36 48 39C48 42 45 42 45 42H21ZM33 24C35.3869 24 37.6761 23.0518 39.364 21.364C41.0518 19.6761 42 17.3869 42 15C42 12.6131 41.0518 10.3239 39.364 8.63604C37.6761 6.94821 35.3869 6 33 6C30.6131 6 28.3239 6.94821 26.636 8.63604C24.9482 10.3239 24 12.6131 24 15C24 17.3869 24.9482 19.6761 26.636 21.364C28.3239 23.0518 30.6131 24 33 24Z" fill="#cecece"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.648 41.9998C15.2033 41.0633 14.9815 40.0364 15 38.9998C15 34.9348 17.04 30.7498 20.808 27.8398C18.9273 27.2603 16.9678 26.9769 15 26.9998C3 26.9998 0 35.9998 0 38.9998C0 41.9998 3 41.9998 3 41.9998H15.648Z" fill="#cecece"/>
        <path d="M13.5 24C15.4891 24 17.3968 23.2098 18.8033 21.8033C20.2098 20.3968 21 18.4891 21 16.5C21 14.5109 20.2098 12.6032 18.8033 11.1967C17.3968 9.79018 15.4891 9 13.5 9C11.5109 9 9.60322 9.79018 8.1967 11.1967C6.79018 12.6032 6 14.5109 6 16.5C6 18.4891 6.79018 20.3968 8.1967 21.8033C9.60322 23.2098 11.5109 24 13.5 24Z" fill="#cecece"/>
    </svg>,
    'Familiares',
);

const MenuClientes = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const [open, setOpen] = useState(false);

    const goTo = (path) => {
        history.push(`/pacientes/${path}`);
    }

    const handleLogout = () => {
        dispatch(resetAuthLogin())
        sessionStorage.clear();
        history.push('/login');
    }
    
    const handleClickLogo = () => {
        window.open('/');
    }

    const getIcon = (icon) => {
        switch (icon) {
            case 'home':
                return <ViewHeadlineIcon sx={{ ml: 2 }} style={{ color: "#E4002B" }} color="secondary" />
            case 'turnos':
                return <PersonIcon sx={{ ml: 2 }} style={true ? { color: "#CECECE" } : { color: "#E4002B" }} />
            case 'estudios':
                return <ThermostatIcon sx={{ ml: 2 }} style={true ? { color: "#CECECE" } : { color: "#E4002B" }} />
            case 'familiares':
                return <FamiliaresIcon sx={{ ml: 2 }} style={true ? { color: "#CECECE" } : { color: "#E4002B" }} />
            case 'perfil':
                return <ManageAccountsIcon sx={{ ml: 2 }} style={true ? { color: "#CECECE" } : { color: "#E4002B" }} />
            case 'logout':
                return <LogoutIcon sx={{ ml: 2 }} style={{ color: "#CECECE" }} />
            default:
                return null
        }
    }

    return (
        <>
            <ModalAlertValidarCuenta open={open} setOpen={setOpen}/>
            <Drawer
                variant="permanent"
                sx={{
                    display: isMobile ? 'none' : 'block',
                    width: '300px',
                    '& .MuiDrawer-paper': { position: 'fixed' , width: 'inherit', display: isMobile ? 'none' : 'block', zIndex: 1 }
                }}
            >
                <Box display={isMobile ? 'none' : 'flex'} justifyContent='center' height='82px' boxShadow='0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'>
                    <img onClick={handleClickLogo} style={{ cursor: 'pointer', width: '100px' }} src="../../images/logo_cma.svg" width="70" alt="Logo cma"></img>
                </Box>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={item.name} disablePadding>
                            <ListItemButton onClick={() => goTo(item.path)}>
                                <ListItemIcon>
                                    {getIcon(item.icon)}
                                </ListItemIcon>
                                <ListItemText 
                                primary={
                                    <Typography fontSize='1rem' sx={{fontFamily: "'Lato', sans-serif"}}>{item.name}</Typography>
                                } 
                                fontWeight='bold' fontSize='.8rem' sx={{fontFamily: "'Lato', sans-serif"}} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ListItem key='logout' disablePadding>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                {getIcon('logout')}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography fontSize='1rem' sx={{fontFamily: "'Lato', sans-serif"}}>Cerrar sesión</Typography>
                                }  />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}

export default MenuClientes;