import { Box } from "@mui/material";
import logo from "../logo_cma_hor.svg";
import logoMobile from "../logo_cma.svg";
import { useHistory } from "react-router-dom";

const NavLogo = ({variant}) => {

    const history = useHistory();

    const handleOnClick = () => {
        history.push('/');
    }

    return (
        <>
            <Box onClick={handleOnClick} sx={{cursor: 'pointer'}}>
                <img src={variant === 'mobile' ? logoMobile : logo} alt="logo cma" style={{width:"100%"}}/>
            </Box>
        </>
    )
}

export default NavLogo;