import { AppBar, Box, Toolbar } from '@mui/material';


const NavbarLogin = () => {
  return (
      <AppBar color="default" position="fixed">
        <Toolbar>
          <Box 
            display='flex'
            width='100%'
            justifyContent="center"
            alignItems="center">
              <img style={{ py: '15px' }} src="../../images/logo_cma.svg" width="20%" alt='Swiss Medical Center'></img>
          </Box>
        </Toolbar>
      </AppBar>
  );
}

export default NavbarLogin;