import { Grid, Box, Typography, Button, Container } from "@mui/material";
import CallButton from "./components/CallButton";
import useIsMobile from "../../Utils/useIsMobile";
import { toggleOpen } from "../../Redux/Reducers/signinModalReducer";
import { useDispatch } from "react-redux";

const styles = {
    display: "flex",
    alignItems: "center",
    background: 'url(./images/headers/landing.png) no-repeat center',
    backgroundSize: 'cover',
    height: '70vh',
    "& .headerCard": {
        marginLeft: "2rem",
        marginRight: "5rem",
        "@media only screen and (min-width: 640px)": {
            marginLeft: "5rem",
        },
        "& h1": {
            color: '#fff',
            fontFamily: "'Lato', sans-serif",
            fontWeight: "600",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontSize: "2rem",
            maxWidth: '600px',
            "@media only screen and (min-width: 640px)": {
                fontSize: "3.5rem",
            },
        },
        "& .headerDesc": {
            color: '#fff',
            fontFamily: "'Lato', sans-serif",
            maxWidth: { md: '300px' }
        },
        "& .btnTurnos": {
            borderRadius: "40px",
            fontFamily: "'Lato', sans-serif",
            fontSize: '.8rem',
            textTransform: 'initial',
            width: '100%',
            maxWidth: '20rem',
            marginTop: '1.5rem',
            padding: '.5rem',
            color: "#fff",
            backgroundColor: "#E4002B",
            borderColor: "#000"
        }
    }
}

const HeaderHome = () => {
    const { isMobile } = useIsMobile();
    const dispatch = useDispatch();

    return (
        <>
            <Box sx={styles}>
            <Container sx={{ paddingX: { xs: 0 }}}>
                <Grid className='headerCard' xs={12} md={6}>
                    {!isMobile ? <CallButton /> : null}
                    <Typography variant="h1">Nuestros centros médicos ambulatorios</Typography>
                    <Typography className="headerDesc">cuentan con prestigiosos profesionales, cómodas instalaciones y la tecnología más avanzada para una atención eficaz, cordial y oportuna.</Typography>
                    {isMobile ? <Button className='btnTurnos' onClick={() => dispatch(toggleOpen(true))}>Turnos y resultados de estudios</Button> : null}
                </Grid>
            </Container>
            </Box>
        </>
    )
}

export default HeaderHome;